import {
  BenefitsApplicationDocument,
  ClaimDocument,
  DocumentType,
  UserDocument,
  isClaimDocument,
} from "../models/Document";

import React from "react";
import ThrottledButton from "./ThrottledButton";
import { Trans } from "react-i18next";
import classnames from "classnames";
import download from "downloadjs";
import findKeyByValue from "../utils/findKeyByValue";
import { formatDateString } from "src/utils/formatDate";
import tracker from "../services/tracker";
import { useTranslation } from "../locales/i18n";

interface DownloadableDocumentProps {
  /** If the user is a Leave Admin, required absence case ID */
  absenceId?: string;
  userId?: string;
  document: BenefitsApplicationDocument | ClaimDocument | UserDocument;
  displayDocumentName?: string;
  downloadClaimDocument?: (
    document: ClaimDocument,
    absenceId: string
  ) => Promise<Blob | undefined>;
  downloadBenefitsApplicationDocument?: (
    document: BenefitsApplicationDocument
  ) => Promise<Blob | undefined>;
  downloadUserDocument?: (
    user_id: string,
    document: UserDocument
  ) => Promise<Blob | undefined>;
  showCreatedAt?: boolean;
  icon?: React.ReactNode;
}

/**
 * Link and metadata for a document
 */
const DownloadableDocument = (props: DownloadableDocumentProps) => {
  const {
    absenceId,
    document,
    downloadClaimDocument,
    downloadBenefitsApplicationDocument,
    downloadUserDocument,
    showCreatedAt,
    displayDocumentName,
    icon,
    userId,
  } = props;
  const { t } = useTranslation();

  const classes = classnames("text-bold margin-top-0", {
    "display-flex flex-align-center": icon,
  });

  const documentName = displayDocumentName || getDocumentName(document, t);

  const handleClick = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    let documentData;
    // TODO (PFMLPB-9527): DRY on click download handlers in DownloadableDocument
    if (isClaimDocument(document) && downloadClaimDocument && absenceId) {
      documentData = await downloadClaimDocument(document, absenceId);
    } else if (downloadBenefitsApplicationDocument) {
      documentData = await downloadBenefitsApplicationDocument(
        document as BenefitsApplicationDocument
      );
    } else if (downloadUserDocument && userId) {
      documentData = await downloadUserDocument(
        userId,
        document as UserDocument
      );
    }
    if (documentData) {
      download(
        documentData,
        displayDocumentName || documentName,
        document.content_type || "application/pdf"
      );
    }
  };

  return (
    <div className="usa-prose">
      <ThrottledButton
        className={classes}
        onClick={handleClick}
        type="button"
        variation="unstyled"
      >
        {icon}
        {displayDocumentName || documentName}
      </ThrottledButton>
      {showCreatedAt && (
        <div
          className="text-base-dark text-normal"
          title="downloadable document created date"
        >
          <Trans
            i18nKey="components.downloadableDocument.createdAtDate"
            values={{ date: formatDateString(document.created_at) }}
          />
        </div>
      )}
    </div>
  );
};

function getDocumentName(
  document: ClaimDocument | BenefitsApplicationDocument | UserDocument,
  t: (arg: string, arg2?: { context: string }) => string
) {
  const docTypes: string[] = [
    DocumentType.appealAcknowledgment,
    DocumentType.appealPostponementAgency,
    DocumentType.appealPostponementApproved,
    DocumentType.appealPostponementDenied,
    DocumentType.appealReinstatementDenied,
    DocumentType.appealReinstatementGranted,
    DocumentType.approvalNotice,
    DocumentType.approvalNoticeExplanationOfWages,
    DocumentType.approvalOfApplicationChange,
    DocumentType.approvedLeaveDatesCancelled,
    DocumentType.denialNotice,
    DocumentType.denialNoticeExplanationOfWages,
    DocumentType.denialOfApplication,
    DocumentType.denialOfApplicationChange,
    DocumentType.explanationOfWages,
    DocumentType.requestForInfoNotice,
    DocumentType.withdrawalNotice,
    DocumentType.maximumWeeklyBenefitChangeNotice,
    DocumentType.benefitAmountChangeNotice,
    DocumentType.leaveAllotmentChangeNotice,
    DocumentType.approvedTimeCancelled,
    DocumentType.changeRequestApproved,
    DocumentType.changeRequestDenied,
    DocumentType.overpaymentFullBalanceDemand,
    DocumentType.overpaymentFullBalanceRecovery,
    DocumentType.overpaymentFullBalanceRecoveryManual,
    DocumentType.overpaymentPartialBalance,
    DocumentType.overpaymentPayoffNotice,
    DocumentType.intermittentTimeApprovalNotice,
    DocumentType.intermittentTimeReported,
    DocumentType.appealReturnedToAdjudication,
    DocumentType.appealApproved,
    DocumentType.appealDismissedExempt,
    DocumentType.appealDismissedOther,
    DocumentType.appealHearingVirtualFillable,
    DocumentType.appealModifyDecision,
    DocumentType.appealRFI,
    DocumentType.appealWithdrawn,
    DocumentType.paymentReceivedUpdatedOverpaymentBalance,
    DocumentType.overpaymentFullDemandErBenefits,
    DocumentType.overpaymentFullDemandIntermittent,
    DocumentType.overpaymentFullDemandLeaveChange,
    DocumentType.overpaymentFullDemandPaidTimeOff,
    DocumentType.overpaymentFullDemandUi,
    DocumentType.overpaymentFullDemandWorkersComp,
    DocumentType.overpaymentFullRecoveryErBenefits,
    DocumentType.overpaymentFullRecoveryIntermittent,
    DocumentType.overpaymentFullRecoveryLeaveChange,
    DocumentType.overpaymentFullRecoveryPaidTime_off,
    DocumentType.overpaymentFullRecoveryUi,
    DocumentType.overpaymentFullRecoveryWorkersComp,
    DocumentType.overpaymentPartialBalance,
    DocumentType.overpaymentPartialDemandErBenefits,
    DocumentType.overpaymentPartialDemandIntermittent,
    DocumentType.overpaymentPartialDemandUi,
    DocumentType.overpaymentPartialDemandWorkersComp,
    DocumentType.overpaymentPartialLeaveChange,
    DocumentType.overpaymentPartialPaidTimeOff,
    DocumentType.overpaymentPaymentReceivedNewBalance,
    DocumentType.overpaymentPayoff,
    DocumentType.dismissalForFailureToAttendHearing,
    DocumentType.eftChangeRequest,
    DocumentType.noticeOfChildSupportWithholding,
    DocumentType.w9TaxForm,
  ];
  if (docTypes.includes(document.document_type)) {
    return t("components.downloadableDocument.noticeName", {
      context: findKeyByValue(DocumentType, document.document_type) || "",
    });
  } else {
    tracker.trackEvent(
      "Received unexpected document type, so rendering generic label",
      { document_type: document.document_type }
    );

    return t("components.downloadableDocument.noticeName");
  }
}

export default DownloadableDocument;
